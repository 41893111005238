import React from 'react';

import '../styles/ProjectContainer.css';
import { IoLaptopOutline, IoOpenOutline } from 'react-icons/io5';


interface Props{
	title: string;
	company?: string;
	position?: string;
	timeFrame?: string;
	location?: string;
	description: string;
	technologies: string;
	websiteLink?: string;
}

const ProjectContainer: React.FC<Props> = (props) =>{
	return(
	<div className='project-information-container'>
		<h2 className='company'>{props.company}</h2>
		<div className='title-container'>
			<h2 className='project-title'>{props.title}</h2>
			{props.websiteLink ? <a href={props.websiteLink} className="link-icon" target='_blank'><IoOpenOutline/></a> : ''}
		</div>
		<h2 className='position'>{props.position}</h2>
		<p className='time-frame'>{props.timeFrame}</p>
		<p className='location'>{props.location}</p>
		<p className='description'>{props.description}</p>
		<p className='technologies'><strong>Used technologies:</strong> {props.technologies}</p>
	</div>
	)
}

export default ProjectContainer;