import React from 'react';
import { IoArrowDown, IoLaptopOutline, IoPhonePortraitOutline, IoImagesOutline, IoDesktop  } from "react-icons/io5";

import Header from '../components/Header';
import ProjectContainer from '../components/ProjectContainer';
import PhotoGallery from '../components/PhotoGallery';
import FadeIn from 'react-fade-in';

const MyProjects: React.FC = () =>{
	return(
		<FadeIn>
			<Header
				firstLine='WELCOME.'
				secondLine='TO MY PROJECTS'
			/>

			<p className="icon"><IoPhonePortraitOutline/></p>

			<div className='flex-row-container'>

				<ProjectContainer
					title='Smart Personalized Information'
					company='Move2x'
					position='Intern - Software Development'
					description="Built system to extract raw data from manufacturing execution systems, transform it into information and present it to a user.
							Designed and built a Progressive Web Application to enable personel to interact with the system.
							Deployed the system on a Raspberry Pi minicomputer."
					technologies='Angular, Typescript, RabbitMQ, Flask API, Python, Nginx, uWSGI, Raspberry Pi,
												 Industry 4.0, Festo MES' 
				/>

				<PhotoGallery
					selectedImageStyle={{"width": "25vw", "aspectRatio": "9/16"}}
					photoData={[
						{
							name: 'showcase-gif',
							url: process.env.PUBLIC_URL+'/move2x-project/showcase-gif.gif',
						},
						{
							name: 'landing_page_operator',
							url: process.env.PUBLIC_URL+'/move2x-project/landing_page_operator.png',
						},
						{
							name: 'product_details_1',
							url: process.env.PUBLIC_URL+'/move2x-project/product_details_1.png',
						},
						{
							name: 'product_details_2',
							url: process.env.PUBLIC_URL+'/move2x-project/product_details_2.png',
						},
						{
							name: 'scanner_view',
							url: process.env.PUBLIC_URL+'/move2x-project/scanner_view.png',
						},
					]}
					/>
			</div>

			<p className="icon"><IoDesktop/></p>

			<div className='flex-column-container'>
				<ProjectContainer
					title='Management Tool Website'
					company='InterCollege'
					position='Intern - Software Development'
					description="						
							Built a website and a microservice, to view, generate, and manage Word and Pdf documents. 
							Website and microservice was deployed on Google Cloud Platform. 
							It aims to improve efficiancy of day-to-day tasks and increase productivity of InterCollege employees.
							Participated in other internal projects in field of cyber security and finance."
					technologies='Angular, Typescript, Flask API, Gunicorn, Python, Docx-lib, RegEx, Google Cloud Platform,
												 Firebase auth, Firestore database, Firebase storage, Cloud Run, Docker'
				/>

				<PhotoGallery
					selectedImageStyle={{"width": "70vw", "aspectRatio": "16/9"}}
					photoData={[
						{
							name: 'company_page',
							url: process.env.PUBLIC_URL+'/intercollege-project/company_page.png',
						},
						{
							name: 'architecture',
							url: process.env.PUBLIC_URL+'/intercollege-project/architecture.png',
						},
						{
							name: 'company_details',
							url: process.env.PUBLIC_URL+'/intercollege-project/company_details.png',
						},
						{
							name: 'school_details',
							url: process.env.PUBLIC_URL+'/intercollege-project/school_details.png',
						},
						{
							name: 'doc_generator_sketch',
							url: process.env.PUBLIC_URL+'/intercollege-project/doc_generator_sketch.png',
						}
					]}
				/>
			</div>

			<p className="icon"><IoImagesOutline/></p>

			<div className='flex-row-container'>
				<ProjectContainer
					title='Personal photo gallery website'
					websiteLink='https://gallery.itskalu.dk'
					description="I decided to build this project as my personal photo gallery, instead of uplading pictures on Instagram.
					 	I store all of my photos in a Google Photos album for free. To display my photos on the website I make a GET request on the URL of the ablum,
					  which responds with bunch of HTML code, that also contains actualy URLs to my photos.
						I use RegEx to extract these URLs and some random information (time and picture resolution) and store it in JSON format.
						Finally I display photos with some old school CSS styling."
					technologies='NextJS, Typescript, RegEx, Vercel' 
				/>
			<PhotoGallery
					selectedImageStyle={{"width": "20vw", "aspectRatio": "9/16"}}
					photoData={[
						{
							name: 'screen-1',
							url: process.env.PUBLIC_URL+'/gallery-website/screen-1.png',
						},
						{
							name: 'screen-2',
							url: process.env.PUBLIC_URL+'/gallery-website/screen-2.png',
						},
						
					]}
				/>
			</div>		
			</FadeIn>
	)
}

export default MyProjects;