import React from 'react';

import '../styles/AboutContainer.css';

interface Props{
    imageURL: string;
    title: string;
}

const AboutContainer: React.FC<Props> = (props) =>{

	const [myAge, setMyAge] = React.useState<number>();

	React.useEffect(()=>{
		let dateOfBirth: Date = new Date("2000-12-29")
		let timeDiff = Math.abs(Date.now() - dateOfBirth.getTime());
		let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
		setMyAge(age)		
	}, [])

	return(
	<div className='about-container'>
		<h1>{props.title}</h1>
			<div className="flex-row-container pic-text-container">
				<img className="portrait-image" src={props.imageURL} alt="Portrait" />
					<p className="about-text">
						<i>"Be curious, be investigative and ask yourself why"</i>
						{'\n'}
						{'\n'}
						{'\n'}
						<strong>{myAge}</strong> y.o {'\n'}
						From <strong>Slovakia</strong> 🇸🇰 {'\n'}
						Living and studying in <strong>Denmark</strong> 🇩🇰 {'\n'}
						Web💻 and mobile📱 dev with passion for backend📡🔧 {'\n'}
						Quiet personality, but honest and dedicated worker{'\n'}
						{'\n'}
						check my <a 
							href={process.env.PUBLIC_URL+'/matej_kalocai_cv_2023.pdf'} 
							className="link"
							target="_blank">RESUME(pdf)</a> 
						{'\n'}
						and my <a href='/projects' className="link">PROJECTS</a> 
					</p>
			</div>
	</div>
	)
}

export default AboutContainer;