import React from 'react';

import '../styles/EducationContainer.css';

interface Props{

}

const EducationContainer: React.FC<Props> = (props) =>{
    return(
		<div className="education-container">
			<h1>EDUCATION</h1>
			<ul>
				<li className="school-year-text">2016-2020</li>
				<li>High-school in Slovakia</li>
				<li>Focused on IT</li>
			</ul>

			<ul>
				<li className="school-year-text">2020-2023</li>
				<li>University College of Northern Denmark (UCN)</li>
				<li>AP Degree in Computer Science</li>
			</ul>
		</div>
    )
}

export default EducationContainer;