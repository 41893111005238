import React from 'react'

import '../styles/Header.css'


interface Props{
    firstLine: string;
    secondLine: string;
    thirdLine?: string;
}

const Header : React.FC<Props> = (props) =>{
    return(
        <div className="column-flex-container">
            <h1 className="firstLine">{props.firstLine}</h1>
            <h1 className="secondLine">{props.secondLine}</h1>
            <h1 className="thirdLine">{props.thirdLine}</h1>
        </div>
    )
}


export default Header;
