import React from 'react';

import { IoLogoGithub, IoLogoLinkedin, IoLogoYoutube, IoLogoTwitter} from "react-icons/io5";

import '../styles/LinksContainer.css';

interface Props{

}

const LinksContainer: React.FC<Props> = (props) =>{
    return(
        <div className="links-container">
            <h1>CONNECT!</h1>

			<ul>
				<li>
					<p className="icon-small"><IoLogoGithub/></p>
					<a href='https://github.com/itskalu' target="_blank">GITHUB</a>
				</li>
				<li>
					<p className="icon-small"><IoLogoTwitter/></p>
					<a href='https://twitter.com/its_kalu' target="_blank">TWITTER</a>
				</li>
				<li>
					<p className="icon-small"><IoLogoLinkedin/></p>
					<a href='https://www.linkedin.com/in/kalocai-matej/' target="_blank">LINKEDIN</a>
				</li>
				<li>
					<p className="icon-small"><IoLogoYoutube/></p>
					<a href='https://www.youtube.com/user/KaluLP' target="_blank">YOUTUBE</a>
				</li>
			</ul>
        </div>
    )
}

export default LinksContainer;