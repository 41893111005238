import React, { useEffect } from 'react';

import '../styles/PhotoGallery.css';


interface IPhoto{
	url: string;
	name: string;
}

interface Props{
	photoData: IPhoto[];
	selectedImageStyle?: React.CSSProperties;
}

const PhotoGallery: React.FC<Props> = (props) =>{

	const [photos, setPhotos] = React.useState<Array<IPhoto>>(props.photoData);
	const [selectedPhoto, setSelectedPhoto] = React.useState<IPhoto>(photos[0])

	return(
		<div className='gallery-container'>
			<img className='selected-img' style={props.selectedImageStyle} src={selectedPhoto.url} alt={selectedPhoto.name}/>
			<div className='img-bottom-list'>
				{photos.map((photo)=>(
					<img className='img' src={photo.url} alt={photo.name} onClick={()=>setSelectedPhoto(photo)}/>
				))}
			</div>
		</div>
	)


}

export default PhotoGallery