import React from 'react';
import { IoArrowDown, IoSchool,  } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";
import FadeIn from 'react-fade-in/lib/FadeIn';

import Header from '../components/Header';
import AboutContainer from '../components/AboutContainer';
import EducationContainer from '../components/EducationContainer';
import LinksContainer from '../components/LinksContainer';

import '../styles/PageStyle.css';
import '../styles/ContainerGlobalStyles.css';

const Home: React.FC = () =>{

	return(
		<FadeIn>
			<Header
				firstLine="HELLO I AM"
				secondLine="MATEJ."
			/>

			<p className="icon"><IoArrowDown/></p>
			
			<AboutContainer
				imageURL={process.env.PUBLIC_URL+'/shirt-portrait.jpg'}
				title='ABOUT ME.'
			/>

			<p className="icon"><IoSchool/></p>

			<EducationContainer/>

			<p className="icon"><IoIosLink/></p>

			<LinksContainer/>

		</FadeIn>
	)
}

export default Home;