import React from 'react';
import {RouterProvider, createBrowserRouter } from 'react-router-dom';

import './App.css';

import NavBar from './components/NavBar';
import Home from './pages/Home';
import MyProjects from './pages/MyProjects';



function App() {
	
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		window.addEventListener('resize', () => {
			setWidth(window.innerWidth);
		});
	}, []);

	const router = createBrowserRouter([
		{
			path: "/",
			element: <NavBar />,
			children: [
				{
					index: true, 
					element: <Home/>,
				},
				{
					path: "/projects",
					element: <MyProjects/>,
				},
			]
		}
	]);

	return (
		<RouterProvider router={router}/>
	);
}

export default App;
