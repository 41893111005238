import React from "react";
import {
    BrowserRouter as Router,
    NavLink,
    useLocation,
		Outlet
  } from "react-router-dom";

import '../styles/NavBar.css';


const NavBar : React.FC = () =>{

  return(
		<div>
			<div className="navbar">
				<NavLink 
					className={({ isActive }) =>
						isActive ? 'navbar-link active' : 'navbar-link'
					} 
					to="/"
				>Home</NavLink>
				<NavLink 
					className={({ isActive }) =>
						isActive ? 'navbar-link active' : 'navbar-link'
					} 
					to="/projects"
				>Projects</NavLink>
			</div>
			<Outlet/>
		</div>
  )
}

export default NavBar